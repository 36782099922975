import 'src/components/Loader/Loader.scss';

const Loader = () => {
  return (
    <div>
      <p> Chargement ...</p>
    </div>
  );
};
export default Loader;
