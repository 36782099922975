import React from 'react';
import { RouterName } from 'src/core/app_routes/RouterNames';
import type { RouteObject } from 'react-router-dom';
const Home = React.lazy(() => import('src/pages/default/home/Home'));
const Project = React.lazy(() => import('src/pages/default/project/Project'));

export const defaultRouteConfig: RouteObject[] = [
  {
    path: RouterName.HOME.path,
    element: <Home />,
  },
  {
    path: RouterName.PROJECT.path,
    element: <Project />,
  },
];
