import { PaletteMode, PaletteColorOptions, ThemeOptions } from '@mui/material';

const createColor = (color: string): PaletteColorOptions => {
  return {
    main: color,
  };
};
export const getDesignTokens = (mode: PaletteMode): ThemeOptions => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          // palette values for light mode
          primary: createColor('#fcc9d1'),
          secondary: createColor('#82a2ff'),
          background: {
            default: '#fff',
            paper: '#EDEFF2',
          },
          text: {
            primary: '#1e1e1e',
          },
        }
      : {
          // palette values for dark mode
          primary: createColor('#fff0db'),
          secondary: createColor('#82a2ff'),
          background: {
            default: '#252734',
            paper: '#333646',
          },
          text: {
            primary: '#fff',
          },
        }),
  },
});
