import { Container } from '@mui/material';
import { PropsWithChildren } from 'react';

type Props = {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
};
const CustomContainer = ({ children, size }: PropsWithChildren<Props>) => {
  return <Container maxWidth={size ?? 'md'}>{children}</Container>;
};
export default CustomContainer;
