import { Suspense, useState, useMemo, createContext } from 'react';
import 'src/App.scss';
import { BrowserRouter } from 'react-router-dom';
import AppLayout from 'src/core/app_layout';
import Loader from 'src/components/Loader/Loader';
import { createCustomTheme } from './theme';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { PaletteMode } from '@mui/material';

export const ColorModeContext = createContext({ 
  toggleColorMode: () => {},
  mode: 'dark' as PaletteMode, 
});

function App() {
  const [mode, setMode] = useState<PaletteMode>('dark');
  const colorMode = useMemo(
    () => ({
      // The dark mode switch would invoke this method
      toggleColorMode: () => {
        setMode((prevMode: PaletteMode) =>
          prevMode === 'light' ? 'dark' : 'light',
        );
      },
      mode
    }),
    [mode],
  );
  const theme = useMemo(() => createCustomTheme(mode), [mode]);
  return (
    <div className="App">
      <Suspense fallback={<Loader />}>
        <BrowserRouter>
          <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <AppLayout />
            </ThemeProvider>
          </ColorModeContext.Provider>
        </BrowserRouter>
      </Suspense>
    </div>
  );
}

export default App;
