import React, { useState } from 'react';
import Menu from 'src/components/Menu/Menu';
import { MenuOptions } from 'src/shared/types/component';
import { Avatar, Box, IconButton, Drawer, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu'; // Import du bouton menu burger
import Logo from 'src/asset/image/v-alphabet-icon.svg';
import CustomContainer from 'src/components/Container/CustomContainer';
import './Header.scss';

const Header = () => {
  const { palette: themeColor } = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false); // État pour le Drawer mobile

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleMenuClick = () => {
    setMobileOpen(false); // Ferme le Drawer
  };

  const itemsMenu: MenuOptions[] = [
    { label: 'Acceuil', path: '#headline', actived: false },
    { label: 'Competences', path: '#skills', actived: false },
    { label: 'Portfolio', path: '#project', actived: false },
    { label: 'Contact', path: '#contact', actived: false },
  ];

  return (
    <header
      className="header"
      style={{ background: themeColor.background.paper }}>
      <CustomContainer>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <Avatar
            alt="Victoire Ondelet"
            src={Logo}
          />
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: {  xs: 'flex !important' , md: 'none !important' } }} // Le bouton s'affiche seulement sur les petits écrans
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Menu list={itemsMenu} />
          </Box>
        </Box>
      </CustomContainer>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }} // Meilleur rendement sur les mobiles
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
        }}>
        <Menu
          list={itemsMenu}
          layout="vertical"
          onMenuItemClick={handleMenuClick}
        />
      </Drawer>
    </header>
  );
};

export default Header;
