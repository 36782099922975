import React, {useContext} from 'react';
import MenuItem from '@mui/material/MenuItem';
import { MenuOptions } from 'src/shared/types/component';
import { Box, IconButton, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ColorModeContext } from 'src/App';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import NightlightOutlinedIcon from '@mui/icons-material/NightlightOutlined';


interface Props {
  list: MenuOptions[];
  layout?: 'horizontal' | 'vertical';
  onMenuItemClick?: () => void; // Fonction de rappel pour gérer les clics sur les éléments de menu
}

const Menu = ({ list, layout = 'horizontal', onMenuItemClick  }: Props) => {
  const { palette: themeColor } = useTheme();
  const { mode, toggleColorMode } = useContext(ColorModeContext);

  const handleLinkClick = () => {
    if (onMenuItemClick) {
      onMenuItemClick(); // Appelle la fonction de rappel lorsque le lien est cliqué
    }
  };

  return (
    <nav>
      <Box
        sx={{
          display: 'flex',
          flexDirection: layout === 'vertical' ? 'column' : 'row',
          justifyContent: 'flex-end',
          alignItems: layout === 'vertical' ? 'center' : 'flex-end',
          p: 1,
          m: 1,
          gap: 2,
        }}>
        {list.map((item, index) => (
          <MenuItem
            key={index}
            onClick={handleLinkClick}>
            <Link
              href={item.path}
              underline="none"
              sx={{
                color: themeColor.text.primary,
                fontSize: 22,
                letterSpacing: 1,
                textTransform: 'capitalize',
                '&:hover': {
                  opacity: 1,
                },
              }}>
              {item.label}
            </Link>
          </MenuItem>
        ))}
          <div>
            <IconButton onClick={toggleColorMode}>
                {mode === 'light' ? <NightlightOutlinedIcon /> : <LightModeOutlinedIcon />}
            </IconButton>
        </div>
      </Box>
      
    </nav>
  );
};

export default Menu;
